a {
  text-decoration: none;
  color: #3a523e;
}

a:hover {
  color: black;
}

body {
  font-family: "Quicksand", sans-serif;
  background-color: #dde1e7;
  color: #3a523e;
  margin: 0;
}

.header-main-container {
  max-width: 1366px;
  margin: auto;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-bottom: 5rem;
  padding-top: 1rem;
  text-align: center;
}

.create-container {
  border: 1px solid black;
  border-radius: 0.5rem;
}

.upload-category {
  width: 15rem;
  font-size: 1.3rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border: none;
  padding: 0.5rem;
  text-align: center;
}

.img-order,
.category-order {
  width: 6rem;
  font-size: 1.3rem;
  border: none;
  border-left: 1px solid black;
  padding: 0.5rem;
  text-align: center;
}

.img-order {
 border-radius: 0 0.5rem 0.5rem 0;
}

.add-category-btn {
  border: none;
  background-color: white;
  font-size: 1.3rem;
  padding: 0.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
  border-left: 1px solid black;
}

.add-category-btn:hover {
  background-color: lightgray;
}

.upload-input {
  display: none;
}

.upload-label {
  cursor: pointer;
  font-size: 1.3rem;
  border: none;
  background-color: lightgrey;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 20rem;
  text-align: center;
}

.upload-btn {
  padding: 0.5rem;
  font-size: 1.3rem;
  width: 15rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  border: 1px solid black;
}

.progress {
  width: 30%;
}

.uploaded {
  font-size: 1.1rem;
  color: green;
  font-weight: 700;
}

.delete-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.delete-options {
  width: 15rem;
  padding: 0.5rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
}

.delete-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
}

.delete-item-container {
  position: relative;
  display: grid;
  grid-template-columns: 95% 5%;
  justify-content: center;
  border: 1px solid black;
  border-radius: 0.5rem;
}

.delete-item {
  width: 100%;
  border-radius: 0.5rem 0 0 0.5rem;
}

.delete-confirmation {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #808080cc;
}

.delete-btn {
  height: 100%;
  width: 100%;
  border: none;
  border-left: 1px solid black;
  border-radius: 0 0.5rem 0.5rem 0;
}

.delete-dialog {
  background: white;
  padding: 3rem;
  border-radius: 1rem;
}

.delete-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
}

.delete-dialog-btn {
  font-size: 1.1rem;
  padding-inline: 0.8rem;
  border-radius: 0.5rem;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
  padding: 0 4%;
  background-color: #dde1e7;
}

.menu-btn {
  position: absolute;
  display: none;
  flex-direction: column;
  height: 1.25rem;
  width: 2.25rem;
  top: 30px;
  right: 30px;
  border: none;
  background-color: transparent;
}

.first-stripe,
.second-stripe,
.third-stripe {
  position: absolute;
  height: 0.187rem;
  width: 1.5rem;
  right: 10px;
  background-color: #3a523e;
  transition: all 0.5s ease-in-out;
}

.first-stripe {
  top: 0;
}

.second-stripe {
  top: 0.5rem;
}

.third-stripe {
  bottom: 0;
}

.close-first {
  transform: rotate(45deg);
  transform-origin: left;
}

.close-second {
  opacity: 0;
}

.close-third {
  transform: rotate(-45deg);
  transform-origin: left;
}

.mobile-menu {
  position: fixed;
  right: -100%;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  padding-top: 4rem;
  gap: 0.5rem;
  font-size: 1.4rem;
  background-color: #dde1e7aa;
  width: 100%;
  height: 100vh;
  text-align: center;
  transition: all 0.25s ease-in-out;
}

.animate {
  animation: fadeInOut 0.25s;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.deanimate {
  animation: fadeOutIn 0.25s;
}

@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.mobile-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100vw;
  padding: 2rem 0;
}

.mobile-link {
  width: 100%;
}

.links-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  width: 20%;
  font-size: 1.4rem;
  top: 38%;
  left: 10%;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title-image {
  width: 50rem;
}

.position-title {
  margin: 0;
  margin-top: -1.5rem;
  font-size: 1.4rem;
  color: #3a523e;
}

.media-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 20%;
  top: 35%;
  right: 10%;
}

.artstation-btn {
  display: flex;
  justify-content: center;
}

.artstation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  background-color: #dde1e7;
  color: #3a523e;
  text-align: center;
  gap: 3rem;
  margin-bottom: 5rem;
  margin: auto;
  padding-bottom: 3rem;
}

.category-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 20%;
  margin-right: 20%;
  border-top: 1px solid black;
  padding: 1rem;
}

.landscape-container {
  width: 100%;
  margin-inline: auto;
}

.portrait-container{
  display: flex;
  width: 100%;
  margin: auto;
}

.portrait-container2,
.portrait-container3 {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  gap: 1rem;
}

.portrait-link {
  display: flex;
  width: 100%;
  margin: auto;
}

.portrait-link2 {
  display: flex;
  width: calc((100% - 1rem) / 2);
}

.portrait-link3 {
  display: flex;
  width: calc((100% - 2rem) / 3);
}

.landscape-link {
  display: flex;
  width: 100%;
  margin: auto;
}

.landscape-image {
  width: 100%;
}

.portrait-image{
 width: 100%;
}

.slider-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: #000000dd;
}

.category-title {
  font-size: 1.4rem;
  padding-bottom: 0.5rem;
  background-color: #ffffff99;
  width: min-content;
  white-space: nowrap;
  margin: auto;
  padding: 0.25rem 0.5rem 0 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.contact-main-container {
  text-align: center;
}

.contact-title {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #3a523e;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.form-input {
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-width: 30rem;
}

.textarea {
  height: 12rem;
}

.contact-btn {
  border: none;
}

.about-me {
  width: 50%;
  margin: auto;
  text-align: center;
}

.slider-main-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
}

.slider-container {
  width: 100vw;
  height: 100vh;
}

.slider-close {
  position: absolute;
  border: none;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding: 0;
}

.close-icon {
  color: gray;
  mix-blend-mode: difference;
  font-size: 2rem;
}

.next-slide,
.prev-slide {
  border: none;
  position: absolute;
  background-color: transparent;
  color: white;
  top: 45%;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}

.next-slide {
  right: 10px;
}

.prev-slide {
  left: 10px;
}

.chev-right,
.chev-left {
  font-size: 2rem;
  mix-blend-mode: difference;
  color: gray;
}

.about-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: 5rem;
  padding-bottom: 3rem;
}

.about-containers-top,
.about-containers {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  color: #3a523e;
}

.personal-img {
  justify-self: flex-end;
  width: 37.5%;
  padding-right: 1rem;
}

.about-info-container {
  padding-left: 1rem;
  width: 50%;
  border-left: 1px solid #3a523e;
}

.about-title {
  font-size: 1.4rem;
}
.about-text-top,
.about-text {
  border-right: 1px solid #3a523e;
  padding-top: 3rem;
  padding-right: 1rem;
  text-align: right;
  margin-bottom: 0;
}

.work-container {
  display: flex;
}

.work-img-top,
.work-img {
  padding-top: 3rem;
  padding-left: 1rem;
  width: 25%;
}

.text {
  padding-top: 3rem;
  padding-left: 1rem;
  margin-bottom: 0;
}

.fullscreen-image {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000000dd;
}

@media screen and (max-width: 1000px) {
  .header-main-container {
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;
  }

  .header {
    position: static;
    padding-inline: 0;
    align-items: baseline;
    min-height: 10rem;
  }

  .menu-btn {
    display: flex;
  }

  .mobile-menu {
    background-color: #ffffffff;
  }

  .title-image {
    margin-top: 1.5rem;
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .position-title {
    margin-top: -1rem;
    font-size: 1.1rem;
  }

  .links-container {
    display: none;
  }

  .media-container {
    display: none;
  }

  .main-container {
    gap: 1.5rem;
  }

  .category-container {
    padding: 0.75rem 0;
    gap: 0.75rem;
    margin: 0;
    width: 100vw;
  }

  .category-title {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .image-container {
    height: fit-content;
  }

  .image-container:hover {
    opacity: 0.7;
    height: auto;
  }

  .artwork-image {
    width: 100%;
    object-fit: contain;
  }

  .slider-container {
    width: fit-content;
    max-width: none;
    overflow-y: hidden;
  }

  .slider-image {
    width: auto;
    max-height: 100vh;
    max-width: none;
  }

  .slider-close {
    position: absolute;
    border: none;
    right: 1rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    transition: all 0.5s ease-in-out;
    background-color: transparent;
  }

  .artstation-btn {
    width: 100%;
  }

  .about-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    margin: 1rem;
    max-width: 500px;
    margin: auto;
  }

  .about-containers-top {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .about-containers {
    grid-template-columns: 20% 50%;
    justify-content: center;
    width: 100%;
  }

  .personal-img {
    justify-self: baseline;
    width: 50%;
    height: fit-content;
    border-right: none;
    padding-left: 1rem;
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .about-info-container {
    padding-inline: 1rem;
    width: 50%;
  }

  .about-title {
    font-size: 1.4rem;
  }

  .about-text-top {
    padding-top: 0;
    padding-left: 0;
    width: 100%;
  }

  .work-img-top {
    padding-top: 0;
    padding-left: 1rem;
    width: 40%;
  }

  .about-text-top,
  .about-text {
    width: fit-content;
    justify-self: flex-end;
  }

  .work-container {
    display: flex;
    flex-direction: column;
  }

  .work-img {
    padding-top: 3rem;
    padding-left: 1rem;
    width: 40%;
  }

  .text {
    padding-top: 1rem;
    padding-left: 1rem;
    margin-bottom: 0;
  }

  .contact-title {
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #3a523e;
  }

  .form-input {
    min-width: 20rem;
  }
}

@media screen and (max-width: 500px ) {
  .about-containers {
    grid-template-columns: 30% 70%;
    width: 100%;
  }

  .about-text {
    width: 100%;
  }
}

@media screen and (max-height: 500px) {
  .mobile-menu {
    overflow-y: scroll;
  }
}

@media screen and (min-width: 2000px) {
  .main-container {
    max-width: 1920px;
  }
}
